import React from "react";
import ReactDOM from "react-dom";

class Cities extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        const cities = this.props.cities.map((item, key) => {
            let className = "db c4 a2 tdn";
            if (this.props.selectedCity === item.id) {
                className = "db c4 a2 tdn active";
            }
            return (
                <div key={key} className="city fl tac">
                    <button onClick={() => this.props.onChangeCity(item.id)} href="#"
                            className={className}>{item.title}</button>
                </div>
            )
        });
        return (
            <div id="cities">
                <div className="choose-city">
                    <span className="text-color-gray">Vyberte si, odkiaľ ste</span>
                    <img src="/images/design/arrow-cities.png" alt="" className="arrow-cities"/>
                </div>
                <div className="cb"></div>
                {cities}
                <div className="cb"></div>
            </div>

        );
    }
}

module.exports = Cities;
