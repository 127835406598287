import React from "react";
import ReactDOM from "react-dom";

var moment = require('moment');
require('moment/locale/sk');

class Days extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            week: moment().format('YYYY-MM-DD')
        };
        this.loadNextWeek = this.loadNextWeek.bind(this);
    }

    loadNextWeek(day)
    {
        this.props.onChangeDay(day);
        this.setState({
            week: moment(day,'DD.MM.YYYY').format("YYYY-MM-DD")
        });

    }



    render() {
        const listDays = [];
        for (let i = 0; i < 8; i++) {
            let day = moment(this.state.week).weekday(i);
            var selectedDay = this.props.selectedDay;
            let dayDate = day.format('DD.MM.YYYY');
            let dayName = day.format('dddd');
            var active = (selectedDay === dayDate ? 'db c3 a3 tdn active' : 'db c3 a3 tdn');
            if (1) {
                listDays.push(
                    <div key={i} className="title fl f1 h5 tac ttu">
                        <button onClick={() => this.props.onChangeDay(dayDate)} title={dayName}
                                className={active}><strong>{dayName}</strong><br/>{dayDate}</button>
                    </div>
                )
            }else{
                /*listDays.push(
                    <div key={i} className="title fl f1 h5 tac ttu">
                        <button onClick={() => this.loadNextWeek(dayDate)} title="Ďalej"
                                className="db c3 a3 tdn">Ďalej<br/>{dayDate}
                        </button>
                    </div>
                )*/
            }

        }

        return (
            <div id="titles">
                {listDays}

                <div className="cb"></div>

            </div>

        );
    }
}

module.exports = Days;
