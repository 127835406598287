import React from "react";

class Restaurants extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.foodTypes = {
            1: {
                id: 2,
                title: 'Polievka',
                icon: "/images/design/icons/16x16/soap.png"
            },
            2: {
                id: 1,
                title: 'Hlavné jedlo',
                icon: '/images/design/icons/16x16/main.png'
            },
            3: {
                id: 5,
                title: 'Prílohy',
                icon: '/images/design/icons/16x16/plus.png'
            },
            4: {
                id: 3,
                title: 'Dezert',
                icon: '/images/design/icons/16x16/desert.png'
            },
            5: {
                id: 4,
                title: 'Šalát',
                icon: '/images/design/icons/16x16/salat.png'
            },

            6: {
                id: 6,
                title: 'Hotové jedlá',
                icon: '/images/design/icons/16x16/main.png'
            }

        }
    }

    render() {
        if (this.props.items) {
            const restaurant = this.props.items.map((item, key) => {
                var food_type = 0;
                var foods_list = [];
                let phone = item.telephone.split(',')[0];
                const foods = Object.keys(this.foodTypes).map((key) => {
                    let type = this.foodTypes[key];
                    let foods = [];
                    item.foods.map((food, key2) => {
                        if (food.food_type == type.id) {
                            foods.push (
                                <h5 className="offer-item" key={key2}>
                                    <span className="fr fwn c2 price">{food.price} €</span>
                                    <span className="c1">{food.weight} </span>
                                    <span className="fwn">{food.title + " "} </span>
                                    <span className="fwn">{food.side_dish} </span>
                                    <span className="fwn c2">{food.allergens} </span>
                                </h5>);
                        }
                    });
                    if (foods.length > 0) {
                        return (
                            <div className="group" key={key}>
                                <div className="list fl">
                                    <h3 className="offer-heading c2">
                                        <img src={type.icon} alt="Obrázok" />
                                        {type.title}
                                    </h3>
                                    {foods}
                                </div>
                                <div className="cb"></div>
                            </div>
                        );
                    }
                })
                /*const foods = item.foods.map((food, key2) => {
                    foods_list.push(
                        <h5 className="offer-item" key={key2}>
                            <span className="fr fwn c2 price">{food.price} €</span>
                            <span className="c1">{food.weight} </span>
                            <span className="fwn">{food.title + " "} </span>
                            <span className="fwn">{food.side_dish} </span>
                            <span className="fwn c2">{food.allergens} </span>
                        </h5>);
                    if (food_type !== food.food_type) {
                        food_type = food.food_type;
                        let tempFood = foods_list;
                        foods_list = [];
                        return (
                            <div className="group" key={key2}>
                                <div className="list fl">
                                    <h3 className="offer-heading c2">
                                        <img src={this.foodTypes[food.food_type].icon} alt="Obrázok" />
                                        {this.foodTypes[food.food_type].title}
                                    </h3>
                                    {tempFood}
                                </div>
                                <div className="cb"></div>
                            </div>
                        );
                    }
                });*/

                let phones = item.telephone.split(',');
                console.log(phones);
                const phonesItems = phones.map((phone)=>{
                    return(
                        <>
                        <a className="" href={"tel:" + phone}>
                            <span className="icon"><img src="/images/design/icon-phone.png" alt="tel" /></span>
                            <span className="contact">{phone}</span>
                        </a>
                        <br />
                        </>
                    )
                })
                return (
                    <React.Fragment key={key}>
                        <div className="restaurant" key={key}>
                            <div className="table">
                                <h3 className="h4">
                                    <a href={"/restauracie/" + item.url} title="Hotel Limba***"
                                       className="c2 a1 tdn">
                                        {item.title}
                                    </a>
                                </h3>
                                <span className="text-color-gray">{item.location}</span>
                                <div className="cb"></div>
                                <div className="restaurant-info">
                                    <div className="image fl">
                                        <a href={"/restauracie/" + item.url} title="{item.title}">
                                            <img src={'/' + item.main_img} alt="Obrázok" />
                                        </a>
                                    </div>
                                    <div className="info fl">
                                        <span className="price f1 c3">{item.price_from}</span>
                                        <div className="cb"></div>
                                        <div className="contact-short">
                                            {phonesItems}

                                        </div>
                                        <div className="contact-short">
                                            <a className="" href={"mailto:" + item.email}>
                                            <span className="icon">
                                                <img src="/images/design/icon-envelope.png" alt="email" />
                                            </span>
                                                <span className="contact">{item.email}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="cb"></div>

                                <div className="delivery" dangerouslySetInnerHTML={{__html: item.perex}}>
                                </div>
                            </div>
                            <div className="offers f1">
                                {foods}
                            </div>
                            <div className="cb"></div>
                        </div>
                        <div className="cb"></div>
                    </React.Fragment>
                )
            })
            return (
                <div id="assigns">
                    {restaurant}
                </div>
            );
        } else {
            return (
                <div id="assigns">
                </div>
            );
        }

    }
}


export default Restaurants;