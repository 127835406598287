import Menu from './components/menu.js';
import React from "react";
import ReactDOM from "react-dom";
import ContactForm from "./components/ContactForm";
import RegisterRestaurantForm from "./components/RegisterRestaurantForm";


document.addEventListener("DOMContentLoaded", function (event) {

    let doc = document;
    let hamburger = document.querySelector('.hamburger');
    if (hamburger) {
        hamburger.addEventListener('click', function () {
            let menu = document.querySelector('header nav');
            menu.classList.toggle('active');
        })
    }

    let route = document.getElementById('route');
    let contactFormE = document.getElementById('contact-form');
    let RegisterRestaurantFormE = document.getElementById('register-restaurant-form');
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    let selectCities = doc.querySelector('.select-label');
    let cities = [];
    if (selectCities) {
        let items = selectCities.querySelectorAll('li');
        items.forEach((item) => {
            cities.push(JSON.parse(item.getAttribute('data-data')));
            item.addEventListener('click', () => {
                changeCity(JSON.parse(item.getAttribute('data-data')));
            })
        })

        selectCities.querySelector('button').addEventListener('click', () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(UserLocation);
            }
            // Default to Washington, DC
            else {
                NearestCity(38.8951, -77.0367);
            }
        })

        selectCities.addEventListener('click',()=>{
            selectCities.classList.toggle('open');
        })
    }
    if (route) {
        ReactDOM.render(<Menu city={route.getAttribute('data-city')} />, route);
    }

    if (contactFormE) {
        ReactDOM.render(<ContactForm />, contactFormE);
    }

    if (RegisterRestaurantFormE) {
        ReactDOM.render(<RegisterRestaurantForm />, RegisterRestaurantFormE);
    }

    function changeCity(city) {

        if (route) {
            if (history.pushState && city['url']) {
                history.pushState(null, null, '/denne-menu/' + city['url']);
            }
            doc.querySelector('.page-wrap h1').scrollIntoView({ behavior: 'smooth'});
            ReactDOM.render(<Menu city={city['id']} />, route);
        } else {
            window.location.href = '/denne-menu/' + city['url'];
        }

        selectCities.querySelector('p').textContent = city['title'];
    }


// Callback function for asynchronous call to HTML5 geolocation
    function UserLocation(position) {
        console.log(position);
        NearestCity(position.coords.latitude, position.coords.longitude);
    }


// Convert Degress to Radians
    function Deg2Rad(deg) {
        return deg * Math.PI / 180;
    }

    function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
        lat1 = Deg2Rad(lat1);
        lat2 = Deg2Rad(lat2);
        lon1 = Deg2Rad(lon1);
        lon2 = Deg2Rad(lon2);
        var R = 6371; // km
        var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
        var y = (lat2 - lat1);
        var d = Math.sqrt(x * x + y * y) * R;
        return d;
    }

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    }

    function deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    function NearestCity(latitude, longitude) {
        var minDif = 99999;
        var closest;
        console.log(cities);
        for (let index = 0; index < cities.length; ++index) {
            var dif = getDistanceFromLatLonInKm(latitude, longitude, cities[index]['lat'], cities[index]['long']);
            if (dif < minDif) {
                closest = index;
                minDif = dif;
            }
        }


        // alert(cities[closest]['title']);
        changeCity(cities[closest]);
    }

    function setCookie(c_name, value, expiredays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + expiredays);
        document.cookie = c_name + "=" + escape(value) +
            ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString()) + ";path=/";
    }

    // getLocation();

    /*
	***************************************************
		SOCIAL PAGES SHARE
	***************************************************
	*/
    $('.open-modal-share').click(function(event) {
        var width  = 575,
            height = 400,
            left   = ($(window).width()  - width)  / 2,
            top    = ($(window).height() - height) / 2,
            url    = this.href,
            opts   = 'status=1' +
                ',width='  + width  +
                ',height=' + height +
                ',top='    + top    +
                ',left='   + left;
        window.open(url, 'twitter', opts);
        return false;
    });



    $("#cross-newsletter").click(function () {
        setCookie('show_newsletter_banner', 0, 1);
        if ($('.newsletter-popup').is(":visible")) {

            $('.newsletter-popup').fadeOut(function () {
                $(this).remove();
            });
            $('.fade-background').fadeOut(function () {
                $(this).remove();
            });
        }
    });


});

