import React, {Component} from 'react';
import 'whatwg-fetch'
class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.contactForm = null;
        this.checkbox = null;
        this.name = null;
        this.surname = null;
        this.email = null;
        this.subject = null;
        this.message = null;

        this.validateEmail = this.validateEmail.bind(this);
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.name.value == '') {
            alert('Zadajte meno!');
            return;
        }
        if (this.surname.value == '') {
            alert('Zadajte priezvisko!');
            return;
        }
        if (!this.validateEmail(this.email.value)) {
            alert('Neplatný formát e-mailu!');
            return;
        }
        if (this.subject.value == '') {
            alert('Zadajte predmet!');
            return;
        }

        if (this.message.value == '') {
            alert('Vyplňte správu!');
            return;
        }

        if (!this.checkbox.checked) {
            alert('Potvrïte osobné údaj');
            return;
        }
        const data = new FormData(event.target);

        window.fetch('/ajax/contact-form.php', {
            'method': 'POST',
            body: data
        }).then(result => {
            return result.json();
        }).then(data => {
            let msg = data.msg;
            let code = data.code;
            if (code == 1) {
                alert('Podarilo sa úspešne odoslať');
                this.contactForm.reset();
            } else {
                alert('Ooops... nepodarilo sa odoslať správu, skúste to neskôr.');
            }
        })
    }


    render() {

        return (
            <form action="" onSubmit={this.handleSubmit} ref={ref => (this.contactForm = ref)}>
                <div className="row">
                    <div className="col-6 form-group">
                        <input ref={ref => (this.name = ref)} name="name" type="text" placeholder="KRSTNÉ MENO*"
                               className="form-control" />
                    </div>
                    <div className="col-6 form-group">
                        <input ref={ref => (this.surname = ref)} name="surname" type="text" placeholder="PRIEZVISKO"
                               className="form-control" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 form-group">
                        <input ref={ref => (this.email = ref)} name="email" type="text" placeholder="VÁŠ E-MAIL*"
                               className="form-control" />
                    </div>
                    <div className="col-6 form-group">
                        <input ref={ref => (this.subject = ref)} name="subject" type="text" placeholder="PREDMET*"
                               className="form-control" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 form-group">
                        <textarea ref={ref => (this.message = ref)} className="form-control" name="text"
                                  placeholder="SPRÁVA *"></textarea>
                    </div>
                </div>

                <label htmlFor="checkbox">
                    <input ref={ref => (this.checkbox = ref)} name="gdpr" id="checkbox" type="checkbox" />
                    Súhlasím so <a href="">spracovaním osobných údajov*</a>
                </label>

                <button type="submit">Odoslať</button>
            </form>
        );
    }
}

export default ContactForm;
