import React, {Component} from 'react';
import 'whatwg-fetch'

class RegisterRestaurantForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'package': 1,
            'additional': ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.contactForm = null;
        this.checkbox = null;
        this.name = null;
        this.tel = null;
        this.email = null;
        this.street = null;
        this.city = null;
        this.psc = null;
        this.ico = null;
        this.dic = null;

        this.validateEmail = this.validateEmail.bind(this);
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.name.value == '') {
            alert('Zadajte meno!');
            return;
        }

        if (!this.validateEmail(this.email.value)) {
            alert('Neplatný formát e-mailu!');
            return;
        }

        const data = new FormData(event.target);
        window.fetch('/ajax/register-restaurant-form.php', {
            'method': 'POST',
            body: data
        }).then(result => {
            return result.json();
        }).then(data => {
            let msg = data.msg;
            let code = data.code;
            if (code == 1) {
                alert('Podarilo sa úspešne odoslať');
                this.contactForm.reset();
            } else {
                alert('Ooops... nepodarilo sa odoslať správu, skúste to neskôr.');
            }
        })
    }

    handleChangeCheckbox(event) {
        let name = event.target.name;
        let data = event.target.value;

        this.setState((prevState) => ({
            ...prevState,
            [name]: data
        }));
    }


    render() {
        return (
            <form action="" onSubmit={this.handleSubmit} ref={ref => (this.contactForm = ref)}>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <input ref={ref => (this.name = ref)} name="name" type="text" className="form-control"
                               placeholder="Meno a priezvisko (názov firmy)" />
                    </div>

                    <div className="form-group col-sm-6">
                        <input ref={ref => (this.tel = ref)} name="tel" type="text" className="form-control"
                               placeholder="Tel. číslo" />
                    </div>

                    <div className="form-group col-sm-6">
                        <input ref={ref => (this.email = ref)} name="email" type="text" className="form-control"
                               placeholder="Email" />
                    </div>

                    <div className="form-group col-sm-4">
                        <input ref={ref => (this.street = ref)} name="street" type="text" className="form-control"
                               placeholder="Ulica" />
                    </div>

                    <div className="form-group col-sm-4">
                        <input ref={ref => (this.city = ref)} name="city" type="text" className="form-control"
                               placeholder="Mesto" />
                    </div>

                    <div className="form-group col-sm-4">
                        <input ref={ref => (this.psc = ref)} name="psc" type="text" className="form-control"
                               placeholder="PSČ" />
                    </div>

                    <div className="form-group col-sm-4">
                        <input ref={ref => (this.ico = ref)} name="ico" type="text" className="form-control"
                               placeholder="IČO" />
                    </div>

                    <div className="form-group col-sm-4">
                        <input ref={ref => (this.dic = ref)} name="dic" type="text" className="form-control"
                               placeholder="DIČ" />
                    </div>

                    <div className="form-group col-sm-4">
                        <input ref={ref => (this.dicDph = ref)} name="dicDph" type="text" className="form-control"
                               placeholder="DIČ DPH" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <h3>Vyberte si svoj balíček</h3>
                        <div className="form-group">
                            <label htmlFor="standard-package"><input onChange={(e) => this.handleChangeCheckbox(e)}
                                                                     value="1" id="standard-package" type="radio"
                                                                     name="package[]" /> Štandard <strong>12.50 €</strong> /
                                mesačne
                            </label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="premium-package"><input onChange={(e) => this.handleChangeCheckbox(e)}
                                                                    value="2" id="premium-package" type="radio"
                                                                    name="package[]" /> Premium <strong>17.50 €</strong> /
                                mesačne
                            </label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exclusive-package">
                                <input onChange={(e) => this.handleChangeCheckbox(e)} value="3" id="exclusive-package"
                                       type="radio"
                                       name="package[]" /> Exclusive <strong>35
                                €</strong> /
                                mesačne
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <h3>Vyberte si doplnkovú službu</h3>
                        <div className="form-group">
                            <label htmlFor="additio-1">
                                <input onChange={(e) => this.handleChangeCheckbox(e)} value="4" id="additio-1"
                                       type="checkbox"
                                       name="package[]" /> Nahrávanie menu na stránku <strong>5
                                €</strong> / mesačne</label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="addition-2">
                                <input onChange={(e) => this.handleChangeCheckbox(e)} value="5" id="addition-2"
                                       type="checkbox"
                                       name="package[]" /> Nahrávanie jedál na FB a IG <strong>5
                                €</strong> / mesačne</label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="addition-3">
                                <input onChange={(e) => this.handleChangeCheckbox(e)} value="6" id="addition-3"
                                       type="checkbox"
                                       name="package[]" /> Fotky jedál <strong>5 €</strong> /
                                mesačne</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <button type="submit" className="btn">Odoslať</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default RegisterRestaurantForm;
