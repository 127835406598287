import React from "react";
import ReactDOM from "react-dom";
import 'whatwg-fetch';
import Cities from "./home/cities.js";
import Days from "./home/days.js";
import Restaurants from "./home/restaurants.js";

var moment = require('moment');
require('moment/locale/sk');

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDay: moment().format('DD.MM.YYYY'),
            selectedCity: null,
            restaurants: []
        };
    }

    componentDidMount() {
        console.log(this.props);
        let formData = new FormData();
        formData.append('day', moment(this.state.selectedDay, 'DD-MM-YYYY').format('YYYY-MM-DD'));
        formData.append('city', this.props.city ? this.props.city : null);
        window.fetch('/ajax/load-restaurants.php', {
            method: 'POST',
            dataType: 'json',
            body: formData
        }).then((response) => response.json())
            .then((responseData) => {
                this.setState({
                    selectedCity: this.props.city ? this.props.city : null,
                    restaurants: responseData
                });
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.city !== this.props.city) {
            let formData = new FormData();
            formData.append('day', moment(this.state.selectedDay, 'DD-MM-YYYY').format('YYYY-MM-DD'));
            formData.append('city', this.props.city ? this.props.city : null);
            window.fetch('/ajax/load-restaurants.php', {
                method: 'POST',
                dataType: 'json',
                body: formData
            }).then((response) => response.json())
                .then((responseData) => {
                    this.setState({
                        selectedCity: this.props.city ? this.props.city : null,
                        restaurants: responseData
                    });
                });
        }
    }

    handleChangeDay(day) {
        let formData = new FormData();
        formData.append('day', moment(day, 'DD-MM-YYYY').format('YYYY-MM-DD'));
        formData.append('city', this.state.selectedCity);
        window.fetch('/ajax/load-restaurants.php', {
            method: 'POST',
            dataType: 'json',
            body: formData
        }).then((response) => response.json())
            .then((responseData) => {
                this.setState({
                    selectedDay: day,
                    restaurants: responseData
                });
            });
    }

    handleChangeCity(city) {
        if (city === this.state.selectedCity)
            city = null;

        let formData = new FormData();
        formData.append('day', moment(this.state.selectedDay, 'DD-MM-YYYY').format('YYYY-MM-DD'));
        formData.append('city', city);
        window.fetch('/ajax/load-restaurants.php', {
            method: 'POST',
            dataType: 'json',
            body: formData
        }).then((response) => response.json())
            .then((responseData) => {
                this.setState({
                    selectedCity: city,
                    restaurants: responseData
                });
            });

    }


    render() {
        return (
            <React.Fragment>
                {/*<Cities selectedCity={this.state.selectedCity} onChangeCity={(data)=>this.handleChangeCity(data)} cities={this.props.cities}/>*/}
                <Days selectedDay={this.state.selectedDay} onChangeDay={(data) => this.handleChangeDay(data)} />
                <Restaurants items={this.state.restaurants} />
            </React.Fragment>
        );
    }
}

module.exports = Menu;
